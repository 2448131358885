import { captureException, withSentry } from "@sentry/remix";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import type { Locale } from "shared/i18n";
import type { User } from "@/payload-types";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
  useNavigation,
  useRouteLoaderData,
} from "@remix-run/react";
import { useChangeLanguage } from "remix-i18next/react";
import { useTranslation } from "react-i18next";
import i18next from "~/i18next.server";
import Header from "~/components/Header";
import Footer from "~/components/Footer";
import { ToastContainer } from "react-toastify";
import "virtual:uno.css";
import "@unocss/reset/tailwind-compat.css";
import "react-toastify/dist/ReactToastify.css";
import "~/global.css";
import { AuthContext } from "~/providers/Auth";
import { CartProvider } from "~/providers/Cart";
import { useEffect } from "react";
import { envClient } from "~/env.server";
import { i18nCookie } from "./cookies";
import Gutter from "~/components/layout/Gutter";
import Heading from "~/components/layout/Heading";
import LoadingBar from "react-top-loading-bar";
import Cookies, { CookieConsentProvider } from "~/providers/Cookies";
import { createTitle } from "./util/createTitle";
import { cn } from "./util/cn";
import { getPayload } from "./util/getPayload.server";

export async function loader({ request }: LoaderFunctionArgs) {
  const locale = (await i18next.getLocale(request)) as Locale;
  const payload = await getPayload();
  const [site, navigation, auth, serializedI18nCookie] = await Promise.all([
    payload.findGlobal({
      slug: "site",
      locale,
      select: {
        taxRate: true,
        checkout: {
          allowedCountries: true,
          urlShippingPolicy: true,
          urlTaxExemptionInfo: true,
          urlTerms: true,
          urlReturnPolicy: true,
        },
        urlPrivacyPolicy: true,
        heroImage: true,
      },
    }),
    payload.findGlobal({
      slug: "navigation",
      locale,
    }),
    payload.auth(request),
    i18nCookie.serialize(locale),
  ]);

  const data = {
    env: envClient,
    locale,
    user: auth.user,
    site,
    navigation,
    i18nCookie: serializedI18nCookie,
  };

  // console.log("root loader", JSON.stringify(data.site, null, 2));
  // console.log("root loader", JSON.stringify(data, null, 2).length);

  return data;
}

export function ErrorBoundary() {
  const error = useRouteError();
  const { t } = useTranslation();

  // captureRemixErrorBoundaryError(error);

  // Track all errors that are not HTTP 4xx in sentry
  if (
    !isRouteErrorResponse(error) ||
    (isRouteErrorResponse(error) && (error.status < 400 || error.status >= 500))
  ) {
    captureException(error);
  }

  return (
    <Gutter className="flex flex-1 flex-col justify-center text-center">
      <Heading>
        {isRouteErrorResponse(error)
          ? `HTTP ${error.status} - ${error.status === 404 ? t("ui.error.pageNotFound") : (error.data ?? error.statusText)}`
          : "Unknown Error"}
      </Heading>
    </Gutter>
  );
}

export const meta: MetaFunction = () => [{ title: createTitle() }];

export const links: LinksFunction = () => [
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
];

export const handle = {
  i18n: "common",
};

export function Layout({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();
  const data = useRouteLoaderData<typeof loader>("root");
  const { locale, i18nCookie, user, navigation, site, env } = data ?? {
    locale: i18n.language,
    user: undefined,
    i18nCookie: "",
    navigation: {
      main: [],
      footer: [],
    },
  };

  useChangeLanguage(locale);

  // Set the locale cookie
  useEffect(() => {
    document.cookie = i18nCookie;
  }, [locale, i18nCookie]);

  // Update the user's locale in Payload
  useEffect(() => {
    if (user?.id) {
      fetch("/auth/set-locale", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locale,
        }),
      });
    }
  }, [locale, user]);

  const { state } = useNavigation();

  return (
    <html lang={locale} dir={i18n.dir()} className="font-sans">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {/* we can use `useEnv` everywhere but not in entry.client.tsx, so we need to pass the env to the window object here */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(env)}`,
          }}
        />
      </head>
      <body>
        <LoadingBar
          color="#ffce0b"
          height={2}
          progress={state === "loading" ? 66 : 100}
        />
        <CookieConsentProvider>
          <AuthContext.Provider value={{ user: user as User | null }}>
            <CartProvider taxRate={site?.taxRate ?? 0}>
              <main className="flex min-h-[100vh] flex-col">
                <Header navigationItems={navigation.main} />
                <ToastContainer
                  position="bottom-right"
                  autoClose={3000}
                  toastClassName={(context) =>
                    cn(
                      "relative flex min-h-0 rounded justify-between overflow-hidden cursor-pointer bg-white border-gray-500 shadow p-4 text-black text-sm font-medium",
                      {
                        "border-green-500 bg-green-100 text-green-700":
                          context?.type === "success",
                        "border-red-500 bg-red-100 text-red-700":
                          context?.type === "warning",
                      },
                    )
                  }
                  icon={false}
                  closeButton={true}
                />
                <div className="flex min-h-[100%] flex-1 flex-col">
                  {children}
                </div>
              </main>
              <Footer navigationItems={navigation.footer} />
              <ScrollRestoration />
              <Scripts />
              <Cookies />
            </CartProvider>
          </AuthContext.Provider>
        </CookieConsentProvider>
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);
