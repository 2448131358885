import type { Navigation as NavigationType } from "@/payload-types";
import { Link, NavLink, useLocation } from "@remix-run/react";
import Gutter from "./layout/Gutter";
import { useTranslation } from "react-i18next";
import { useUser } from "~/providers/Auth";
import { useCart } from "~/providers/Cart";
import { cn } from "~/util/cn";
import { Cross as Hamburger } from "hamburger-react";
import { useEffect, useState } from "react";
import Navigation from "./Navigation";
import { useScrollDirection } from "~/util/useScrollDirection";
import Image from "./Image";
import { useEnv } from "~/util/useEnv";

export type Props = React.HTMLProps<HTMLDivElement> & {
  navigationItems: NavigationType["main"];
};

const Header: React.FC<Props> = ({ navigationItems, className, ...props }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { cart } = useCart();
  const env = useEnv();
  const scrollDirection = useScrollDirection();

  // mobile menu
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
    if (isMenuOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };
  useEffect(() => {
    setIsMenuOpen(false);
    document.body.style.overflow = "auto";
  }, [pathname]);

  return (
    <header
      {...props}
      className={cn(
        "z-1 sticky top-0 flex h-16 w-full items-center border-b border-gray-100 bg-white transition-[top] duration-500 sm:h-28",
        scrollDirection === "down" && "-top-16 sm:-top-28",
        className,
      )}
    >
      <Gutter className="flex justify-between">
        <Link to="/" prefetch="intent" className="contents">
          <Image
            src={`${env?.NEXT_PUBLIC_FRONTEND_URL ?? ""}/img/logo.png`} // this image is in the public folder
            alt="logo"
            className="h-auto w-[120px] select-none object-contain sm:w-[180px]"
            width={1867}
            height={503}
            sizes="(min-width: 768px) 180px, 120px"
            srcSet={[
              {
                options: { width: 120 },
                size: "120w",
              },
              {
                options: { width: 180 },
                size: "180w",
              },
              {
                options: { width: 240 },
                size: "240w",
              },
              {
                options: { width: 360 },
                size: "360w",
              },
            ]}
          />
        </Link>
        {navigationItems && (
          <Navigation items={navigationItems} className="max-sm:hidden" />
        )}
        <nav className="flex items-center justify-between gap-2">
          <NavLink
            to="/search"
            className="flex items-center gap-2 p-2"
            prefetch="intent"
          >
            <div className="i-solar:magnifer-linear cursor-pointer text-3xl hover:scale-105" />
          </NavLink>
          <Link
            to="/cart"
            prefetch="intent"
            className="relative flex items-center gap-2 p-2"
          >
            <div className="i-solar:bag-linear text-3xl hover:scale-105" />
            {(cart?.items?.length || 0) > 0 && (
              <div className="absolute -right-1 -top-1 rounded bg-red-500 px-1 text-sm text-white">
                {cart?.items?.length}
              </div>
            )}
          </Link>
          <Link
            to={user && !user.isGuest ? "/account" : "/auth/sign-in"}
            prefetch="intent"
            className="flex items-center gap-2 p-2"
          >
            <div className="i-solar:user-circle-outline text-3xl hover:scale-105" />
            <span className="max-sm:hidden">
              {user && !user.isGuest ? user.name : t("auth.signIn")}
            </span>
          </Link>
          <div className="z-50 sm:hidden">
            <Hamburger
              onToggle={toggleMenu}
              toggled={isMenuOpen}
              distance={"sm"}
              size={24}
            />
          </div>
          {navigationItems && (
            <div
              className={cn(
                "z-49 fixed right-0 top-0 h-full w-full translate-x-0 overflow-y-auto bg-white px-8 pt-12 transition-transform",
                !isMenuOpen && "translate-x-full",
              )}
            >
              <div className="mt-8 w-full flex-1 sm:pl-6 lg:pl-12 xl:mt-12">
                <Navigation
                  items={navigationItems}
                  className="flex-col text-lg"
                />
              </div>
            </div>
          )}
        </nav>
      </Gutter>
    </header>
  );
};
export default Header;
