import type { Navigation as NavigationType } from "@/payload-types";
import Gutter from "./layout/Gutter";
import Navigation from "./Navigation";

export type Props = React.HTMLProps<HTMLDivElement> & {
  navigationItems: NavigationType["footer"] | null | undefined;
};

const Footer: React.FC<Props> = ({ navigationItems, ...props }) => {
  return (
    <footer
      {...props}
      className="w-full border-t border-gray-100 bg-white py-8 text-sm text-gray-500"
    >
      <Gutter className="flex justify-between">
        <Navigation items={navigationItems ?? []} type={"footer"} />
        <div className="flex flex-col justify-between text-end text-xs">
          <a
            href="https://www.billiger.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img
              src="https://cdn.billiger.com/partnerbutton/light/29129"
              alt="billiger.de - der Preisvergleich"
              title="billiger.de - der Preisvergleich"
            />{" "}
          </a>
          ©{new Date().getFullYear()} PV Sale24
        </div>
      </Gutter>
    </footer>
  );
};
export default Footer;
